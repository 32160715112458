<template>
  <div>
    <div class="section section__coaches-page pb-0">
      <div class="section__wrapper">
        <div class="title-with-icon mb-5">
          <div class="title-with-icon__icon title-with-icon__icon_coach"></div>
          <div class="title-with-icon__title">Тренеры и наставники</div>
        </div>

        <ul class="nav nav-tabs mb-5" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link active" id="talant-tab" data-toggle="tab" href="#talant" role="tab"
               aria-controls="online"
               aria-selected="false">Мы ищем таланты!</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="experts-tab" data-toggle="tab" href="#experts" role="tab"
               aria-controls="experts"
               aria-selected="false">Для экспертов</a>
          </li>
          <li class="nav-item" role="presentation" v-if="hasShowTutorsTab()">
            <a class="nav-link" id="online-tab" data-toggle="tab" href="#online" role="tab"
               aria-controls="online"
               aria-selected="false">Тренеры и наставники</a>
          </li>
          <li v-if="false" class="nav-item" role="presentation">
            <a class="nav-link" id="basic-tab" data-toggle="tab" href="#basic" role="tab" aria-controls="basic"
               aria-selected="true">Экспортный стандарт для МСП</a>
          </li>
          <li v-if="false" class="nav-item" role="presentation">
            <a class="nav-link" id="region-tab" data-toggle="tab" href="#region" role="tab" aria-controls="region"
               aria-selected="true">Отбор региональных тренеров</a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane show active"  id="talant" role="tabpanel" aria-labelledby="talant-tab">
            <talant />
          </div>
          <div v-if="hasShowTutorsTab()" class="tab-pane fade" id="online" role="tabpanel" aria-labelledby="online-tab">
            <div class="container-grid section__coaches-page-docs mb-3">
              <document title="Реестр тренерского состава программы экспортных семинаров" link="https://exporteduru.servicecdn.ru/media/default/0003/26/c1cdc497a496e94f2e68872b363b89c179931801.pdf"/>
              <document title="Списки наставников, тренеров и трекеров акселерационной программы «Экспортный форсаж»" link="https://exporteduru.servicecdn.ru/media/default/0003/08/4d8b0f69223d344f76a2d4c4da37aef8a86f8363.pdf" />
              <document title="Реестр тренерского и наставнического составов программы «Экспортный мини-МБА»" link="https://exporteduru.servicecdn.ru/media/default/0003/36/9d901590b85d4f4653622bc015be3b97a0610789.pdf"/>
              <document title="Портфолио тренеров и наставников акселерационных программ" link="https://exporteduru.servicecdn.ru/media/default/0002/93/31d65fdcb39b9631536292546db145f1b1569bc3.pdf" />
              <document title="Аккредитованные операторы-провайдеры акселерационной программы «Экспортный форсаж»" link="https://exporteduru.servicecdn.ru/media/default/0003/11/7deb028368eb6208f1a8c4e936ff77af0caf79cf.pdf" />
              <document title="Аккредитованные операторы-провайдеры программы «Экспортный мини-МБА»" link="https://exporteduru.servicecdn.ru/media/default/0003/36/d46162c8e48e908f1db5d2c72687a5282fb6f16e.pdf" />
            </div>
            <h2 class="content_title mb-4">Список тренеров и наставников <template v-if="total">({{ total }})</template></h2>
            <div class="section__calendar-search mb-3">
              <input type="text" v-model="search" placeholder="Поиск по преподавателям">
              <div @click="clearFilters" v-if="search" class="section__calendar-search-submit">Сбросить фильтр</div>
            </div>
            <div class="container-grid section__coaches-page-filters mb-2">
              <div class="container-grid__item">
                <select id="all-regions" v-model="region" data-placeholder="Все регионы">
                  <option :value="null" selected>Все регионы</option>
                  <option v-for="region in regions" :key="region.id" :value="region">
                    {{ region.region_name_ru }}
                  </option>
                </select>
              </div>
              <div class="container-grid__item">
                <select id="all-programms" v-model="program" data-placeholder="Все программы">
                  <option :value="null" selected>Все программы</option>
                  <option v-for="program in programs" :key="program.id" :value="program">
                    {{ program.list_title }}
                  </option>
                </select>
              </div>
              <div class="container-grid__item" :class="{disabled: !program}">
                <select id="all-courses" v-model="course" :disabled="!program" data-placeholder="Все курсы">
                  <option :value="null" selected>Все курсы</option>
                  <template v-if="program">
                    <option v-for="course in program.courses" :key="course.id" :value="course">
                      {{ course.title }}
                    </option>
                  </template>
                </select>
              </div>
              <div class="container-grid__item" :class="{disabled: !program}">
                <select id="all-statuses" v-model="status" :disabled="!program" data-placeholder="Все статусы">
                  <option :value="null" selected>Все статусы</option>
                  <template v-if="program">
                    <option v-for="status in program.statuses" :key="status.id" :value="status">
                      {{ status.title }}
                    </option>
                  </template>
                </select>
              </div>
            </div>
            <div v-if="tutors" class="container-grid section__coaches-page-coach">
              <div v-for="tutor in tutors" :key="tutor.id" class="container-grid__item" :class="{loading: loading}">
                <tutor-item :tutor="tutor" />
              </div>
            </div>
            <div v-if="total && total > limit" class="d-flex justify-content-center mt-3">
              <paginator :limit="limit" :offset="offset" :count="total"/>
            </div>
          </div>
          <div class="tab-pane fade" id="basic" role="tabpanel" aria-labelledby="basic-tab">
            <basic />
          </div>
          <div class="tab-pane fade" id="region" role="tabpanel" aria-labelledby="region-tab">
            <region />
          </div>
          <div class="tab-pane fade" id="experts" role="tabpanel" aria-labelledby="experts-tab">
            <vacancy />
          </div>
        </div>
      </div>
    </div>
    <page-footer/>
  </div>
</template>

<script>
import $ from 'jquery';
window.jQuery = $
import ApiService from "../../services/api.service";
import Paginator from "../components/Paginator";
import {eventBus} from "@/main";
import PageFooter from "../components/PageFooter";
import {TokenService} from "../../services/token.service";
import Talant from "@/components/tutors/Talant.vue";
import Basic from "@/components/tutors/Basic.vue";
import Region from "@/components/tutors/Region.vue";
import Vacancy from "@/components/tutors/Vacancy.vue";
import Document from "@/components/Document.vue";
import TutorItem from "@/components/tutors/TutorItem.vue";
const LIMIT = 6
export default {
  name: "Tutors",
  components: {TutorItem, Document, Vacancy, Region, Basic, Talant, PageFooter, Paginator},
  data() {
    return {
      programs: null,
      sendForm: {},
      courses: null,
      regions: null,
      region: null,
      program: null,
      course: null,
      status: null,
      tutors: null,
      offset: 0,
      limit: LIMIT,
      total: null,
      search: null,
      posTutors: null,
      id: this.$route.query.id,
      loading: false,
      user: JSON.parse(TokenService.getUser())
    }
  },
  mounted() {
    eventBus.$on('paging', (page) => {
      this.offset = page.offset
      this.loadTutors()
    })
    this.$parent.init()
    ApiService.get('staticpages/tutors').then( res => {
      this.programs = res.data.programs
      this.regions = res.data.regions
    })
    this.loadTutors()
  },
  methods: {
    swap(arr, a, b) {
      arr[a] = arr.splice(b, 1, arr[a])[0];
    },
    setHidden(position, showText) {
      this.tutors = this.tutors.map(function(tutor){
        tutor.showText = false;
        return tutor;
      });

      this.tutors[position].showText = showText;

      if(showText) {
        if((position + 1) % 3 === 0) {
          this.swap(this.tutors, position, position-1);
        }
      } else {
        this.tutors = this.posTutors.slice();
      }
    },
    hasShowTutorsTab() {
      if(!this.user) {
        return false;
      }
      return this.user.roles.indexOf('ROLE_SEMINAR_OPERATOR') > -1 || this.user.roles.indexOf('ROLE_TUTOR') > -1;
    },
    loadTutors(search = null) {
      this.loading = true
      ApiService.get('tutors', {
        params: {
          'filters[course]': this.course ? this.course.id : null,
          'filters[program]': this.program ? this.program.id : null,
          'filters[spec]': this.status ? this.status.id : null,
          'filters[region]': this.region ? this.region.id : null,
          'filters[id]': this.id,
          limit: LIMIT,
          offset: search ? 0 : this.offset,
          search: search
        }
      }).then(res => {
        this.tutors = res.data.tutors
        this.posTutors = this.tutors.slice()
        this.total = res.data.total
        let self = this
        setTimeout(() => {
          var coach_content = $('.coach__content');
          coach_content.each(function (index) {
            $(this).addClass('collapsed');
            $('<span data-index="' + index + '" class="showmore">Подробнее</span>').appendTo($(this));
          });
          $('.showmore').click(function () {
            let index = $(this).data('index')
            if ($(this).parents('.container-grid__item').hasClass('expanded')) {
              $(this).parents('.container-grid__item').removeClass('expanded');
              $(this).text('Подробнее');
              self.setHidden(index, false)
            } else {
              $(this).parents('.container-grid__item').addClass('expanded');
              $(this).text('Свернуть');
              self.setHidden(index, true)
            }
          });
        }, 100)
        this.loading = false
      })
    },
    clearFilters() {
      this.region = null
      this.program = null
      this.course = null
      this.status = null
      this.offset = 0
      this.search = null
      this.loadTutors()
    }
  },
  watch: {
    search(val) {
      if(val.length > 3) {
        this.loadTutors(val)
      }
      if(!val) {
        this.loadTutors()
      }
    },
    region() {
      this.loadTutors()
    },
    program() {
      this.course = null
      this.status = null
      this.offset = 0
      this.loadTutors()
    },
    course() {
      this.offset = 0
      this.loadTutors()
    },
    status() {
      this.offset = 0
      this.loadTutors()
    }
  }
}
</script>

<style scoped>
@media (max-width: 768px) {
  .notice_content {
    font-size: 16px;
  }
}

</style>